// cfg

const getters = {
	getFields(state) {
		return state.fields;
	},
	getTables(state) {
		return state.tables;
	},

};

export default getters;